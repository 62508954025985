import React, { useContext, useEffect } from 'react';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import EventContainer from '../components/EventContainer';
import { EventsContext } from '../provider/EventProvider';
import useSearch from '../hooks/useSearch';

function EventPage() {
  const { state } = useContext(EventsContext);
  const [handleClick] = useSearch();

  useEffect(() => {
    if (!state.events) {
      handleClick();
    }
  }, []);

  return (
    <>
      <SEO />
      <Layout withoutSVG>
        <EventContainer />
      </Layout>
    </>
  );
}

export default EventPage;
